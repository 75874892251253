import React, {FunctionComponent, ReactNode} from 'react'
import styled from "styled-components"
import {
  DashboardWithoutChartsTypes,
  NormalizedPersonalDashboard,
  NormalizedWorkspaceDashboard,
} from "schemas/dashboard"
import EditableTitle from "components/editableTitle/EditableTitle"
import {captureEvent} from "services/GoogleAnalyticsService"

interface Props {
  dashboard: DashboardWithoutChartsTypes
  dashboardTitlePrefix?: ReactNode
  editMode: boolean
  canEditDashboardProperties: boolean
  onConfChange: (data: Omit<NormalizedWorkspaceDashboard, 'charts'> | Omit<NormalizedPersonalDashboard, 'charts'>) => Promise<void>
}

const DashboardTitle: FunctionComponent<Props> = ({dashboard,
                                                    dashboardTitlePrefix,
                                                    canEditDashboardProperties,
                                                    editMode,
                                                    onConfChange}) => {
  return <StyledContainer>
    {dashboardTitlePrefix && <>
      <MenuTitle>
        {dashboardTitlePrefix}
      </MenuTitle>
      <Separator>
        {"/"}
      </Separator></>}
   <EditableTitle
     title={dashboard.title}
     onConfirm={(newTitle) => onConfChange({
       ...dashboard,
       title: newTitle
     })}
     editable={editMode && canEditDashboardProperties}
     triggerGAEvent={() => captureEvent({
       category: 'dashboards',
       action: 'dashboard_title_update',
     })}
    fontSize={24}
     iconSize={24}/>
  </StyledContainer>
}

export default DashboardTitle

const MenuTitle = styled.div`
  color: var(--primary);
`

const Separator = styled.div`
  color: var(--border-color-base);
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 300;
`

const StyledContainer = styled.div`
  display: inline-flex;
  flex-shrink: 1;
  flex-grow: 1;
  font-size: 24px;
  font-weight: 600;
  align-items: center;
`
