import React, {useCallback, useState} from 'react'
import FormTrigger from 'components/forms/FormTrigger'
import HideableForm from "components/forms/Form.Hideable"
import {BaseEditionInformation} from "components/workspace/WorkspaceBridge.SiderContainer"
import {loadDictionaryEntries as loadDictionaryEntriesService} from "services/MetaModelService"
import {FormFooterAltSubmit, MetaModel} from "@biron-data/react-bqconf"
import FormGeneric from "components/forms/Form.Generic"
import {ExpandedWorkspace} from "redux/models/workspace"
import {
  FormDashboardConfProps,
  FormDashboardDataTypes,
  FormGenericProps,
  FormType,
} from "components/forms/Form.types"
import styled from "styled-components"
import FormDashboardConf from "components/forms/dashboard/FormDashboardConf"

interface TriggerableFormProps<T extends BaseEditionInformation> {
  title: string
  metaModel?: MetaModel
  workspace?: ExpandedWorkspace
  value: T
  onChange: (newValue: T) => void,
  name?: string
  trigger: JSX.Element
  descriptionType?: { isPopover: boolean },
  formType: FormType
  environmentId?: number,
  dashboardId?: number,
  altSubmit?: Omit<FormFooterAltSubmit, 'trigger'> & Partial<Pick<FormFooterAltSubmit, 'trigger'>>,
  isDashboard?: boolean
}

const FormTriggerable = <T extends BaseEditionInformation, >({
                                                               formType,
                                                               value,
                                                               title,
                                                               name = title,
                                                               metaModel,
                                                               workspace,
                                                               dashboardId,
                                                               environmentId,
                                                               onChange,
                                                               trigger,
                                                               altSubmit,
                                                               descriptionType,
                                                               isDashboard = false,
                                                             }: TriggerableFormProps<T>) => {
  const [visible, setVisible] = useState(false)

  const handleTriggerClick = useCallback(
    () => {
      setVisible(true)
    },
    [setVisible],
  )
  const onSubmit = useCallback(
    (data: any) => {
      return Promise.resolve(onChange(data))
        .then(result => {
          setVisible(false)
          return result
        })
    },
    [setVisible, onChange],
  )
  const onCancel = useCallback(
    () => {
      setVisible(false)
    },
    [setVisible],
  )

  const loadDictionaryEntries = useCallback(
    (dictionaryCode: string) => loadDictionaryEntriesService(environmentId, dictionaryCode),
    [environmentId],
  )

  return <Container>
      <FormTrigger {...{
        title,
        descriptionType,
        trigger: <trigger.type {...trigger.props} onClick={handleTriggerClick}/>,
      }}/>
    {visible && environmentId && isDashboard && metaModel ? <HideableForm<FormDashboardDataTypes, FormDashboardConfProps> {...{
      renderFormComponent: (props) => <FormDashboardConf {...props}/>,
      data: value as unknown as FormDashboardDataTypes,
      visible,
      formType,
      loadDictionaryEntries,
      name,
      metaModel,
      workspace,
      dashboardId,
      environmentId,
      onConfirm: onSubmit,
      onCancel,
      altSubmit,
    }}/> : <HideableForm<T, FormGenericProps<T>> {...{
      renderFormComponent: (props) => <FormGeneric {...props}/>,
      data: value,
      visible,
      formType,
      name,
      workspace,
      dashboardId,
      environmentId,
      onConfirm: onSubmit,
      onCancel,
      altSubmit,
    }}/>
    }
  </Container>
}

export default FormTriggerable

const Container = styled.span`
    display: flex;
`