import styled from "styled-components"
import {Button, Menu} from "antd"

export const Container = styled.div`
    padding-top: 16px;
    overflow-y: auto;
`

export const StyledMenu = styled(Menu)`
    &&& .ant-menu-submenu-title {
        //padding-left: 24px;
    }
    &&& .ant-menu-item {
        //padding-left: 24px !important;

        .restore, .trash, .unstar {
            display: none;
        }

        :hover {
            .restore, .trash, .unstar {
                display: block;
            }
        }
    }

    &&& .ant-menu-item-disabled {
        color: rgba(255, 255, 255, 0.6) !important;
        cursor: unset;

        :hover {
            background-color: unset;
        }
    }
`

export const StyledMenuWithTopBorder = styled(StyledMenu)`
    border-top: 1px var(--main-text-color) solid;
    
    .ant-menu-submenu-title {
        padding-inline-end: 16px;
        background: rgb(39, 39, 42);
    }
`

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    margin-right: 5px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const FlexContainerStart = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 7px;
`

export const ButtonWithoutMargin = styled(Button)`
    margin: 0;
    padding: 0;
`

export const DashboardTitle = styled.span`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  `
