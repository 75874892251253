import React, {FunctionComponent, PropsWithChildren, ReactNode} from 'react'
import {Layout} from 'antd'
import MainLayoutHeader from 'components/mainLayout/MainLayout.Header'
import {useSelector} from "react-redux"
import styled from "styled-components"
import {getSelection} from "redux/currentDashboard.selector"

interface MainLayoutProps extends PropsWithChildren {
  editMode?: boolean,
  sideContent?: ReactNode,
  onEditModeChange?: (state: boolean) => void
  onDashboardSelectionChange?: any
  canEditDashboardContent?: boolean,
  canEditDashboardProperties?: boolean,
}

const MainLayout: FunctionComponent<MainLayoutProps> = ({
                                                          children,
                                                          canEditDashboardContent,
                                                          canEditDashboardProperties,
                                                          editMode,
                                                          onDashboardSelectionChange,
                                                          onEditModeChange,
                                                          sideContent,
                                                        }) => {
  const dashboardSelection = useSelector(getSelection)

  return <Layout>
    {sideContent}
    <Layout className="main-layout">
      <Layout>
        {dashboardSelection && <MainLayoutHeader {...{
          dashboardSelection,
          onDashboardSelectionChange,
          onEditModeChange,
          canEditDashboardContent,
          canEditDashboardProperties,
          editMode,
        }} />}
        <LayoutContent>
          {children}
        </LayoutContent>
      </Layout>
    </Layout>
  </Layout>
}

const LayoutContent = styled(Layout.Content)`
  overflow-y: auto;
`

export default MainLayout

