/* eslint-disable max-lines */

import React, {forwardRef, useCallback, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {Layout, Layouts, Responsive} from 'react-grid-layout'
import 'components/dashboard/grid/dashboard-grid.css'
import {isEqual, pick} from "lodash"
import {generateLayout, gridCols, hashLinkToChartId, rowHeight} from "components/dashboard/grid/dashboardGridLayoutUtils"
import {MetaModel, DataSelection, GenericChartTypes} from "@biron-data/react-bqconf"
import * as Sentry from "@sentry/react"
import {FloatButton, Result} from 'antd'
import styled from "styled-components"
import {WidgetRef} from "components/widgets/Widget"
import {ChartDtoDetail, ChartDtoDetailTypes} from "types/charts"
import {LayoutWithId} from "redux/models/currentPersonalDashboard"
import {ChevronUpIcon} from "@heroicons/react/outline"
import {IconContainer, ErrorBoundaryResultContainer} from "@biron-data/react-components"
import {runAfterFramePaint, useGetLayoutVisibleBounds} from "components/dashboard/grid/DashboardGrid.utils"
import {useGetChartLayoutForAdd, UseGetChartLayoutForAddType} from 'hooks/useGetLayoutForAddChart'
import {useHandleChartAdd} from "hooks/useHandleAddChart"
import useDispatch from "hooks/useDispatch"
import {useHandleAddChartFromClipboard} from "hooks/useHandleAddChartFromClipboard"
import DividerAddWidget from "components/dashboard/header/dividerAddWidget/DividerAddWidget"
import Language from "language"
import {ExtendedWidgetTypes, WidgetTypes} from "commons/dashboard/dashboard.types"
import {FILTERLINE_HEIGHT} from "components/dashboard/Dashboard.constants"
import WidgetContainerLoader from "components/widgetContainer/WidgetContainer.Loader"
import {DashboardDtoWithoutChartTypes} from "types/dashboards"
import {useMediaDesktop, useMemoDeepCached, useRefs, useScrollToPosition} from "@biron-data/react-hooks"

interface Props {
  environmentId?: number
  metaModel: MetaModel
  dashboard: DashboardDtoWithoutChartTypes
  currentSelection: DataSelection
  charts: ChartDtoDetailTypes[]
  editMode?: boolean,
  canEditDashboardContent: boolean,
  onLayoutChange: (arg: LayoutWithId[]) => void,
  filtersLength: number
  isFilterLineFullSize: boolean
  isFilterInEdition: number
  width?: number
  copiedChart: ChartDtoDetail
}

export interface Handles {
  getChartLayoutForAdd: ReturnType<UseGetChartLayoutForAddType>
  scrollToChart: (chartId: number) => void
}

export default forwardRef<Handles, Props>(function DashboardGrid({
                                                                   environmentId,
                                                                   metaModel,
                                                                   dashboard,
                                                                   currentSelection,
                                                                   charts,
                                                                   editMode,
                                                                   canEditDashboardContent,
                                                                   onLayoutChange,
                                                                   filtersLength,
                                                                   isFilterLineFullSize,
                                                                   width,
                                                                   copiedChart,
                                                                   isFilterInEdition,
                                                                 }, ref) {
  const dispatch = useDispatch()

  const isMediaDesktop = useMediaDesktop()
  const currentLayoutKey = isMediaDesktop ? "lg" : "sm"
  const editable = editMode && isMediaDesktop
  const dashboardForWidget = useMemoDeepCached(
    () => pick(dashboard, ['id', 'title']),
    [dashboard],
  )
  const sortedCharts = useMemo(
    () => [...charts].sort((a, b) => (a.y - b.y) || (a.x - b.x) || (a.id - b.id)),
    [charts],
  )
  const [isChartsMounted, setIsChartsMounted] = useState(false)
  const refGridWrapper = useRef<HTMLDivElement>(null)
  const getLayoutVisibleBounds = useGetLayoutVisibleBounds(refGridWrapper.current)
  const [temporaryWidgetRefs, setTemporaryWidgetRef] = useRefs<WidgetRef>(sortedCharts)

  const [widgetRefs, setWidgetRefs] = useState<{ [id: number]: WidgetRef }>()
  const getWidgetRef = useCallback((index: number) => {
    return widgetRefs?.[index]
  }, [widgetRefs])

  const getChartLayoutPosition = useCallback(
    (chartId: number) => {
      const draggableDomNode = getWidgetRef(sortedCharts.findIndex(chart => chart.id === chartId))?.getDraggableDomNode()
      if (!draggableDomNode || !isChartsMounted) { // occurs when user switch between different design modes (desktop/mobile)
        return null
      }
      const top = draggableDomNode.offsetTop + 5
      const bottom = top + draggableDomNode.offsetHeight
      return {
        top,
        bottom,
      }
    },
    [getWidgetRef, isChartsMounted, sortedCharts],
  )

  const refLayouts = useRef<Layouts>()

  const [isTextChartDynamicHeightUpdated, setIsTextChartDynamicHeightUpdated] = useState(false)

  const layouts = useMemoDeepCached<Layouts>(
    () => {
      setIsTextChartDynamicHeightUpdated(false)
      return generateLayout(sortedCharts, getWidgetRef)
    }, [sortedCharts, getWidgetRef, isTextChartDynamicHeightUpdated],
  )
  const previousLayout = useRef<Layout[]>(layouts.lg)

  refLayouts.current = layouts

  const [resizingChartId, setResizingChartId] = useState<number | null>()

  const saveLayout = useCallback(
    (layout: Layout[]) => {
      if (editable) {
        previousLayout.current = layout
        onLayoutChange(layout.map(({i, x, y, w, h}) => ({
          id: Number(i),
          x,
          y,
          w,
          h,
        })))
      }
    },
    [editable, onLayoutChange],
  )
  const handleLayoutChange = useCallback(
    (layout: Layout[]) => {
      if (currentLayoutKey === 'lg' && !isEqual(layout, previousLayout.current)) {
        saveLayout(layout)
      }
    },
    [currentLayoutKey, saveLayout],
  )
  const handleResizeStart = useCallback(
    (layout: Layout[], oldItem: Layout) => {
      setResizingChartId(Number(oldItem.i))
    },
    [setResizingChartId],
  )
  const handleResizeStop = useCallback(
    (layout: Layout[]) => {
      setResizingChartId(null)
      handleLayoutChange(layout)
    },
    [setResizingChartId, handleLayoutChange],
  )
  const handleDragStop = useCallback(
    (layout: Layout[]) => {
      handleLayoutChange(layout)
    },
    [handleLayoutChange],
  )

  // after a text chart is updated and after the first render occurs we must recompute the layout to take into account the new dynamicHeight
  useLayoutEffect(() => {
      setTimeout(() => runAfterFramePaint(() => {
        const newLayout = generateLayout(sortedCharts, getWidgetRef)
        if (!isEqual(newLayout.lg, layouts.lg)) {
          setIsTextChartDynamicHeightUpdated(true)
          saveLayout(newLayout.lg)
          }
      }), 0)
    },
    [getWidgetRef, layouts.lg, saveLayout, sortedCharts],
  )

  const scrollToPosition = useScrollToPosition(refGridWrapper)

  const scrollToChart = useCallback((chartId: number) => {
    const visibleBounds = getLayoutVisibleBounds()
    setTimeout(() => {
      const widgetPos = getChartLayoutPosition(chartId)
      if (widgetPos && visibleBounds && widgetPos?.bottom > visibleBounds?.bottom) {
        const y = widgetPos.top - (visibleBounds.height / 4)
        scrollToPosition(y)
      }
    }, 200)
  }, [getChartLayoutPosition, getLayoutVisibleBounds, scrollToPosition])

  const getChartLayoutForAdd = useGetChartLayoutForAdd(
    refGridWrapper.current,
    layouts.lg,
    getWidgetRef,
    sortedCharts,
    isChartsMounted,
  )

  useImperativeHandle(ref, () => ({
    getChartLayoutForAdd,
    scrollToChart,
  }), [getChartLayoutForAdd, scrollToChart])


  // Antdesign BackTop provides a method 'target' to specify container that gets called in container scroll event (instead of full window)
  // This method may be called as refGridWrapper is changing, resulting in an error even though BackTop component should have been destroyed
  // So we fallback to divId which is always defined
  const divId = "biron-dashboard-grid"
  const onScrollTopReady = useCallback(() => {
    if (refGridWrapper.current) {
      return refGridWrapper.current as HTMLDivElement
    }
    return document.getElementById(divId) as HTMLDivElement
  }, [])

  const setChartRef = useCallback((node: WidgetRef | null, index: number) => {
    if (node) {
      setTemporaryWidgetRef[index](node)
      if (temporaryWidgetRefs.length === sortedCharts.length) {
        setIsChartsMounted(true)
        setWidgetRefs(temporaryWidgetRefs)
      }
    }
  }, [setTemporaryWidgetRef, sortedCharts.length, temporaryWidgetRefs])

  const handleChartAdd = useHandleChartAdd(
    dispatch.currentDashboard.chartAdd,
    getChartLayoutForAdd,
    (id) => {
      scrollToChart?.(id)
    },
  )

  const handleChartAddFromClipboard = useHandleAddChartFromClipboard(
    copiedChart,
    handleChartAdd,
  )

  const [isAtTheTop, setIsAtTheTop] = useState(true)

  const onGridScroll: React.UIEventHandler<HTMLDivElement> = useCallback((event) => {
    setIsAtTheTop(event.currentTarget.scrollTop === 0)
  }, [])

  return <GridWrapper id={divId} key={String(dashboard.id)} $filterslength={filtersLength}
                      $isfilterlinefullsize={isFilterLineFullSize ? 1 : 0}>
    {refGridWrapper.current && <FloatButton.BackTop visibilityHeight={400} target={onScrollTopReady}>
      <BackTopContent>
        <StyledIconContainer>
          <ChevronUpIcon/>
        </StyledIconContainer>
      </BackTopContent>
    </FloatButton.BackTop>}
    {editable && !isFilterInEdition && isAtTheTop && <UpperAddChart>
      {[0, 1, 2].map((i) => <DividerAddWidget key={i} {...{
        environmentId,
        metaModel,
        dashboard,
        dashboardSelection: currentSelection,
        copiedChart,
        handleChartAdd: (type) => handleChartAdd(type, {y: 0, x: i * 2}),
        handleChartAddFromClipboard: () => handleChartAddFromClipboard({y: 0, x: i * 2}),
        scrollToChart,
        style: 'width: calc(100% / 3 - 15px); position: relative; top: -20px;',
        GASource: "dashboardGrid",
      }}/>)}
    </UpperAddChart>}
    <GridContainer ref={refGridWrapper} onScroll={onGridScroll}>
      <Responsive {...{
        className: "layout",
        useCSSTransforms: false,
        rowHeight: rowHeight + 20,
        style: {marginBottom: `${canEditDashboardContent ? 150 : 10}px`, width: "100%", marginRight: 10, marginLeft: 10, marginTop: 10},
        cols: gridCols,
        autosize: true,
        isResizable: editable,
        isDraggable: editable,
        layouts,
        draggableCancel: ".widget-content, .widget-topper, .ant-modal-wrap, .title-input, .add-chart, .ant-popover, .title-input-trigger, .disable-grab",
        onResizeStart: handleResizeStart,
        onResizeStop: handleResizeStop,
        onDragStop: handleDragStop,
        onLayoutChange: (layout) => {
          if (previousLayout.current && previousLayout.current.length !== layout.length) {
            handleLayoutChange(layout)
          }
        },
        measureBeforeMount: false,
        compactType: "vertical",
        breakpoint: currentLayoutKey,
        margin: [0, 0],
        width: (width ?? 20) - 20, // 20 is the total of margin
      }}>
        {sortedCharts.map((chart, index) =>
          <DivWithPadding key={chart.id} className={getResizeClassNameOverride(chart.type, Boolean(editable))}>
            <Sentry.ErrorBoundary fallback={<ErrorBoundaryResultContainer>
              <Result
                status={"error"}
                title={Language.get("error-occurred")}/>
            </ErrorBoundaryResultContainer>}>
              <WidgetContainerLoader key={chart.id}
                               {...{
                                 ref: (node) => setChartRef(node, index),
                                 environmentId,
                                 metaModel,
                                 dashboard: dashboardForWidget,
                                 dashboardSelection: currentSelection,
                                 chart,
                                 isLinkedTo: chart.id === hashLinkToChartId(dashboard.chartHashLink),
                                 editable,
                                 resizing: chart.id === resizingChartId,
                                 getChartLayoutForAdd,
                                 scrollToChart,
                                 scrollToPosition,
                                 handleChartAdd,
                                 handleChartAddFromClipboard,
                                 copiedChart,
                               }}/>
            </Sentry.ErrorBoundary>
          </DivWithPadding>,
        )}
      </Responsive>
    </GridContainer>

  </GridWrapper>
})

const UpperAddChart = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  top: 10px;
  justify-content: space-between;
`

const DivWithPadding = styled.div`
  padding: 10px;
`

const GridContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`

const getResizeClassNameOverride = (chartType: ExtendedWidgetTypes, editable: boolean) => {
  if (!editable) {
    return 'resize-none'
  } else if (chartType === WidgetTypes.DIVIDER || chartType === WidgetTypes.TARGET || chartType === GenericChartTypes.BOXES) {
    return 'resize-only-width'
  } else {
    return ''
  }
}

const transition = "all .3s cubic-bezier(.645,.045,.355,1);"
const StyledIconContainer = styled(IconContainer)`
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, .45);
  border-radius: 20px;
  -webkit-transition: ${transition}
  transition: ${transition}: hover {
    background-color: rgba(0, 0, 0, .65);
    -webkit-transition: ${transition}
    transition: ${transition}
  }
`

const GridWrapper = styled.div<{ $filterslength: number, $isfilterlinefullsize: number }>`
  position: relative;

    ${({$isfilterlinefullsize, $filterslength}) => {
        if ($filterslength > 0) {
            if ($isfilterlinefullsize) {
      return `
      margin-top: ${(FILTERLINE_HEIGHT)}px;
      height: calc(100% - ${(FILTERLINE_HEIGHT)}px);`
    } else {
      return `
      margin-top: var(--line-height);
      height: calc(100% - var(--line-height));`
    }
  }
    return `
      margin-top: 0px;
      height: 100%;`
}};
  /*from https://stackoverflow.com/questions/44793453/how-do-i-add-a-top-and-bottom-shadow-while-scrolling-but-only-when-needed*/
  background-color: var(--main-background-color);
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
`

const BackTopContent = styled.div`
    background-color: var(--light-grey);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    color: white;
`
