import React from 'react'
import App from './App'
// import 'antd/dist/main.css'
import '@biron-data/biron-antd/dist/standard.scss'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import {createRoot} from "react-dom/client"

dayjs.extend(isoWeek)
dayjs.extend(quarterOfYear)
dayjs.extend(localizedFormat)

const container = document.getElementById('app')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App/>)
