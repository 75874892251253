import React, {FunctionComponent, useCallback, useMemo} from 'react'
import {Button, Layout, notification, Switch} from 'antd'
import styled from "styled-components"
import Language from "language"
import {useSelector} from "react-redux"
import {getDashboardEditMode} from "redux/appEnvironment.selector"
import {useMediaDesktop} from "@biron-data/react-hooks"
import {getCurrentDashboard} from "redux/currentDashboard.selector"
import {DashboardTypes, Folder, PersonalDashboardDtoWithoutChart} from "types/dashboards"
import {IconContainer, TooltipPopover} from "@biron-data/react-components"
import {ArrowCircleUpIcon, BookmarkIcon as OutlineBookmarkIcon, DocumentAddIcon} from "@heroicons/react/outline"
import {BookmarkIcon as SolidBookmarkIcon} from "@heroicons/react/solid"
import useDispatch from "hooks/useDispatch"
import {descriptionTypeNotPopover} from "components/forms/FormTrigger"
import {DashboardDto} from "components/workspace/WorkspaceBridge.SiderContainer"
import {FormKeys} from "components/forms/Form.types"
import {FormItemKeys} from "commons/keywords/form"
import FormTriggerable from "components/forms/Form.Triggerable"
import {getCurrentWorkspaceExpanded} from "redux/workspace.selector"
import {useNavigate} from "react-router-dom"
import {buildWorkspaceUri} from "components/workspace/Workspace.types"
import {ExpandedWorkspace} from "redux/models/workspace"
import {DataSelection} from "@biron-data/react-bqconf"
import DashboardDateSelector from "components/forms/selector/dashboardDate/DashboardDateSelector"
import {DateInterval} from "@biron-data/period-resolver"
import {getCurrentEnvironmentId} from "redux/environment.selector"

interface Props {
  onDashboardSelectionChange?: (selection: DataSelection) => void,
  onEditModeChange?: (state: boolean) => void
  dashboardSelection: DataSelection,
  editMode?: boolean,
  canEditDashboardContent?: boolean,
  canEditDashboardProperties?: boolean,
}

const MainLayoutHeader: FunctionComponent<Props> = ({
                                                      onDashboardSelectionChange,
                                                      dashboardSelection,
                                                      onEditModeChange,
                                                      canEditDashboardContent,
                                                      canEditDashboardProperties,
                                                    }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const editMode = useSelector(getDashboardEditMode)
  const isMediaDesktop = useMediaDesktop()
  const currentDashboard = useSelector(getCurrentDashboard)
  const currentEnvironmentId = useSelector(getCurrentEnvironmentId)
  const currentWorkspace = useSelector(getCurrentWorkspaceExpanded) as ExpandedWorkspace

  const handleDashboardCalendarChange = useCallback(
    (date: Omit<DateInterval, 'name'>) => onDashboardSelectionChange?.({
      ...dashboardSelection as DataSelection, // We consider dashboardSelection is filled here
      date,
    }),
    [dashboardSelection, onDashboardSelectionChange],
  )

  const action = useMemo(() => {
    switch (currentDashboard.type) {
      case DashboardTypes.workspace:
        return (canEditDashboardContent || canEditDashboardProperties) && (
          <EditMode $editmode={editMode ? 1 : 0} $enabled={isMediaDesktop ? 1 : 0} onClick={() => {
            onEditModeChange?.(!editMode)
          }}>
            <Switch {...{
              checked: editMode,
            }}/>
            <SwitchState>
              {editMode ? Language.get('dashboard-edit-mode') : Language.get('dashboard-edit-mode')}
            </SwitchState>
          </EditMode>
        )
      case DashboardTypes.personal: {
        if (!currentDashboard.dashboard) {
          return undefined
        }
        const pinButton = <TooltipPopover size={'small'} mouseEnterDelay={0.4} content={Language.get('personal-dashboard.tooltip.pin')}>
          <Button
          type={currentDashboard.dashboard.type === DashboardTypes.personal && currentDashboard.dashboard.folder === Folder.STARRED ? "primary" : "default"}
          onClick={() => {
            if (currentDashboard.dashboard!.type === DashboardTypes.personal) {
              const isUnstarAction = currentDashboard.dashboard!.folder === Folder.STARRED
              dispatch.currentDashboard.confUpdate({
                ...currentDashboard.dashboard!,
                folder: currentDashboard.dashboard!.folder === Folder.STARRED ? Folder.DRAFT : Folder.STARRED,
              })
              notification.info({
                duration: 2.5,
                key: `${isUnstarAction ? 'unpin' : 'pin'}-draft`,
                message: Language.get(`personal-dashboard.popup.${isUnstarAction ? 'unstar' : 'star'}-draft`, currentDashboard.dashboard!.title),
                placement: 'bottomRight',
              })
            }
          }}><FlexDiv>
          <IconContainer>
            {
              currentDashboard.dashboard.type === DashboardTypes.personal
              && currentDashboard.dashboard.folder === Folder.STARRED ? <SolidBookmarkIcon/> : <OutlineBookmarkIcon/>
            }
          </IconContainer>{Language.get('personal-dashboard.header.button.pin')}</FlexDiv></Button>
        </TooltipPopover>
        const importButton = <TooltipPopover size={'small'} mouseEnterDelay={0.4} content={Language.get('personal-dashboard.tooltip.import')}>
          <Button
          type={"primary"}
          onClick={async () => {
            if (currentDashboard.dashboard && currentDashboard.dashboard!.type === DashboardTypes.personal) {
              const newDashboard = await dispatch.personalDashboards.cloneDashboard({dashboardId: currentDashboard.dashboard.id})
              notification.info({
                duration: 2.5,
                key: `import`,
                message: Language.get(`personal-dashboard.popup.import`, currentDashboard.dashboard!.title),
                placement: 'bottomRight',
              })
              navigate(`/app/personal/${currentEnvironmentId}/${newDashboard.uid}`)
            }
          }}><FlexDiv>
          <IconContainer>
            <DocumentAddIcon/>
          </IconContainer>{Language.get('personal-dashboard.header.button.import')}</FlexDiv></Button>
        </TooltipPopover>
        const restoreButton = <TooltipPopover size={'small'} mouseEnterDelay={0.4} content={Language.get('personal-dashboard.tooltip.restore')}>
          <Button
          type={"primary"}
          onClick={() => {
            if (currentDashboard.dashboard!.type === DashboardTypes.personal) {
              dispatch.currentDashboard.confUpdate({
                ...currentDashboard.dashboard!,
                folder: Folder.DRAFT,
              })
              notification.info({
                duration: 2.5,
                key: `restored-draft`,
                message: Language.get(`personal-dashboard.popup.restored-draft`, currentDashboard.dashboard!.title),
                placement: 'bottomRight',
              })
            }
          }}><FlexDiv>
          <IconContainer>
            <ArrowCircleUpIcon/>
          </IconContainer>{Language.get('personal-dashboard.popup.restore')}</FlexDiv></Button>
        </TooltipPopover>
        return <FlexDiv>
          {currentDashboard.ownedByUser ? (() => {
            switch(currentDashboard.dashboard?.folder) {
              case Folder.DRAFT:
              case Folder.STARRED:
                return pinButton
              case Folder.TRASH:
                return restoreButton
              default:
                return <></>
            }
          })() : importButton}
          {currentDashboard.dashboard.type === DashboardTypes.personal && <FormTriggerable {...{
            descriptionType: descriptionTypeNotPopover,
            trigger: <Button type={"primary"}><FlexDiv><IconContainer><ArrowCircleUpIcon/></IconContainer>{Language.get('personal-dashboard.publish')}</FlexDiv></Button>,
            formType: {type: FormKeys.CLONE_DASHBOARD},
            dashboardId: currentDashboard.dashboard.id,
            workspace: currentWorkspace,
            onChange: async (value: DashboardDto) => {
              if (currentDashboard.dashboard) {
                const clonedDashboard = await dispatch.workspace.cloneDashboard({
                  dashboardId: currentDashboard.dashboard.id,
                  value,
                })
                if (clonedDashboard.type === DashboardTypes.workspace && currentWorkspace) {
                  navigate(buildWorkspaceUri(currentWorkspace, clonedDashboard.uri))
                  dispatch.personalDashboards.updatePersonalDashboardFolder({
                    dashboard: {
                      id: currentDashboard.dashboard.id,
                      uid: (currentDashboard.dashboard as PersonalDashboardDtoWithoutChart).uid,
                    },
                    newFolderState: Folder.TRASH,
                  })
                }
              }
            },
            value: {
              id: currentDashboard.dashboard.id,
              [FormItemKeys.PARENT_MENU]: 1,
              [FormItemKeys.TITLE]: currentDashboard.dashboard.title,
              [FormItemKeys.POSITION_DASHBOARD]: 1,
            },
            name: Language.get('form-title-copy-of-xxx', {ref: currentDashboard.dashboard.title}),
            title: Language.get('navigation-tools-clone-dashboard'),
          }}/>}
        </FlexDiv>
      }
      default:
        return <></>
    }
  }, [canEditDashboardContent, canEditDashboardProperties, currentDashboard.dashboard, currentDashboard.ownedByUser, currentDashboard.type, currentEnvironmentId, currentWorkspace, dispatch.currentDashboard, dispatch.personalDashboards, dispatch.workspace, editMode, isMediaDesktop, navigate, onEditModeChange])

  return <StyledHeader>
    {dashboardSelection.date && <div>
      <DashboardDateSelector value={dashboardSelection.date} onChange={handleDashboardCalendarChange}/>
    </div>}
    {action}
  </StyledHeader>
}

export default MainLayoutHeader

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const StyledHeader = styled(Layout.Header)`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
`

const SwitchState = styled.div`
  margin-left: 8px;
`

const EditMode = styled.div<{ $editmode: number, $enabled: number }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
    cursor: pointer;
  
  &:hover {
    color: var(--primary);

      ${({$editmode}) => $editmode ? '' : `
      .ant-switch{
          background-image: linear-gradient(to right, var(--light-grey), var(--light-grey)), linear-gradient(to right, #fff, #fff);
      }
    `}
  }

    ${({$enabled}) => $enabled ? '' : `
    display: none;
  `}
}
`
