import React, {FunctionComponent} from 'react'
import styled from "styled-components"
import {DatePicker} from "antd"
import classNames from "classnames"
import {PeriodFixed, dayjs, Dayjs} from "@biron-data/period-resolver"

interface Props {
  value: Omit<PeriodFixed, "start" | "end"> & { start: Dayjs; end: Dayjs; }
  onChange: (newValue: Omit<PeriodFixed, "start" | "end"> & { start: Dayjs; end: Dayjs; }) => void
}

const FixedPeriod: FunctionComponent<Props> = ({
                                                 value,
                                                 onChange,
                                               }) => {

  return <StyledRangeCalendar
    style={{width: "100%"}}
    format={"ll"}
    prefixCls={"antcustom-calendar"}
    onChange={(values) => {
      if (values && values[0]) {
        onChange({
          ...value,
          start: values[0],
          end: values[1] ?? values[0],
        })
      }
    }}
    defaultValue={[dayjs(value.start), dayjs(value.end)]}
    className={classNames({
      [`antcustom-calendar-time`]: undefined,
      [`antcustom-calendar-range-with-ranges`]: undefined,
    })}
  />
}

export default FixedPeriod

const StyledRangeCalendar = styled(DatePicker.RangePicker)`
  &&& .antcustom-calendar-footer {
    display: none;
  }

  &&& .antcustom-calendar-range {
    width: 100% !important;
  }

  &&& .antcustom-calendar {
    box-shadow: none;
    width: 100% !important;
  }
`